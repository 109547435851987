
.searchBox{
    height: 100%;
    position:relative; left:40px; top:30px;
}


  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  .suggestions {
    border: 1px solid #999;
    border-top-width: 1px;
    background-color: white;
    list-style: none;
    margin-top: 0;
    margin-left: 0px;

    max-height: 200px;
    position: absolute;
    overflow-y: auto;
    padding-left: 0;
    width: calc(500px + 1rem);
  }
  .suggestions li {
    padding: 0.5rem;
    /*font-size: small;*/
  }
  .suggestion-active,
  .suggestions li:hover {
    background-color: #727574;
    color: #efefed;
    cursor: pointer;
    /*font-weight: 700;*/
  }
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }


 