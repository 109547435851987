/*.App {*/
/*  text-align: center;*/
/*}*/

/*.basic-grid{*/
/*  display: grid;*/
/*  gap: 1rem;*/

/*  grid-template-columns: 0.5fr 1fr;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  color: white;*/
/*}*/


/*!* */
/*.flex-item-left {*/
/*  background-color: #f1f1f1;*/
/*  padding: 10px;*/
/*  flex: 50%;*/
/*} *!*/


/*!* .container{*/
/*  background-color: #fff;*/
/*  padding: 25px 30px;*/
/*  border-radius: 5px;*/
/*  box-shadow: 0 5px 10px rgba(0,0,0,0.15);*/
/*} *!*/
/*!* .container .title{*/
/*  font-size: 25px;*/
/*  font-weight: 500;*/
/*} *!*/



/*!*.content form .user-details{*!*/
/*!*  display: flex;*!*/
/*!*  flex-wrap: wrap;*!*/
/*!*  justify-content: space-between;*!*/
/*!*  margin: 20px 0 12px 0;*!*/
/*!*}*!*/

/*!*form .user-details .input-box{*!*/
/*!*  margin-bottom: 15px;*!*/
/*!*  width: calc(100% / 2 - 20px);*!*/
/*!*}*!*/
/*!*form .input-box span.details{*!*/
/*!*  display: block;*!*/
/*!*  margin-bottom: 5px;*!*/
/*!*}*!*/

/*!*.App-link {*!*/
/*!*  color: #61dafb;*!*/
/*!*}*!*/

/*!*@keyframes App-logo-spin {*!*/
/*!*  from {*!*/
/*!*    transform: rotate(0deg);*!*/
/*!*  }*!*/
/*!*  to {*!*/
/*!*    transform: rotate(360deg);*!*/
/*!*  }*!*/
/*!*}*!*/

/*!** {*!*/
/*!*  box-sizing: border-box;*!*/
/*!*}*!*/

/*!*.main-container {*!*/
/*!*  display: grid;*!*/
/*!*  !* grid-template-columns: 500px 1000px ;*!*/
/*!*  grid-template-rows: 200px 200px; *!*!*/
/*!*  grid-gap: 50px 20px;*!*/
/*!*  grid-template-columns: 400px auto;*!*/


/*!*}*!*/

/*!*.flex-item-left {*!*/
/*!*  padding-left: 20px ;*!*/

/*!*}*!*/

/*!*.flex-item-right {*!*/
/*!*  padding-right: 100px ;*!*/


/*!*}*!*/

/*!*.flex-table{*!*/

/*!*  grid-column-start: 1;*!*/
/*!*  grid-row-start: 2;*!*/
/*!*  grid-column-end: 4;*!*/


/*!*}*!*/