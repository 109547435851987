/* /* .formBox {
    text-align: left;
}

.form-style-1 .field-divided{
	width: 50%;
	
}

.field-long{
	width:max-content;

}

.formstyle{
	width:fit-content;

}


}

.form-style{
	display: flex;
	width: 80%;
	height: 90%;
	border: 1px solid #c3c3c3;
	margin: 0 3rem 2rem 3rem;
	justify-content: space-evenly;
	font-size: 15px;




} */

/*.action-type{*/
/*	display: flex;*/
/*  width: 100%;*/
/*  border: 1px solid #c3c3c3;*/
/*  justify-content: end;*/
/*   padding: 5px 5px;*/
/*   font-size: 13px;*/
/*   margin-bottom: 20px;*/
/*}*/


/*.content form .user-details{*/
/*	display: flex;*/
/*	flex-wrap: wrap;*/
/*	justify-content: space-between;*/
/*	margin: 5px 0 5px 0;*/
/*  }*/
/*  form .user-details .input-box{*/
/*	margin-bottom: 15px;*/
/*	width: calc(100% / 3 - 20px);*/
/*  }*/
/*  form .input-box span.details{*/
/*	display: block;*/
/*	font-weight: 500;*/
/*	margin-bottom: 1px;*/
/*  }*/
  /*.user-details .input-box input{*/
	/*height: 20px;*/
	/*width: 56%;*/
	/*outline: none;*/
	/*font-size: 16px;*/
	/*border-radius: 5px;*/
	/*padding-left: 15px;*/
	/*border: 1px solid #ccc;*/
	/*border-bottom-width: 2px;*/
	/*transition: all 0.3s ease;*/
  /*}*/

  /*form .category label .dot{*/
	/*font-size: 5px;*/
	/*height: 18px;*/
	/*width: 8px;*/
	/*border-radius: 50%;*/
	/*margin-right: 10px;*/
	/*background: #d9d9d9;*/
	/*border: 5px solid transparent;*/
	/*transition: all 0.3s ease;*/
  /*}*/

/*label{*/
/*	font-size: 14px;*/

/*}*/
/*input[type="Number"] {*/
/*	width: 40%;*/

/*}*/
/*	select{*/
/*	outline: none;*/
/*	height: 25px;*/
/*	width: 60%;*/
/*	transition: all 0.3s ease;*/
/*	border-radius: 5px;*/


/*}*/
 /*form .button input {*/
 /*  !*height: 100%;*!*/
 /*  !*width: 100%;*!*/
 /*  !*!*border-radius: 5px;*!*!*/
 /*  !*border: none;*!*/
 /*  color: #fff;*/
 /*  font-size: 18px;*/
 /*  font-weight: 500;*/
 /*  letter-spacing: 1px;*/
 /*  cursor: pointer;*/
 /*  transition: all 0.3s ease;*/
 /*  !*background: linear-gradient(135deg, #71b7e6, #9b59b6);*!*/
 /*}*/

/* .user-details .input-box input:focus,*/
/*.user-details .input-box input:valid{*/
/*  border-color: #246a19;*/
/*}*/
/*  @media(max-width: 584px){*/
/*    .container{*/
/*     max-width: 100%;*/
/*   }*/
/*   form .user-details .input-box{*/
/*       margin-bottom: 15px;*/
/*       width: 100%;*/
/*     }*/
/*     */
/*     form .category{*/
/*       width: 100%;*/
/*     }*/
/*     .content form .user-details{*/
/*       max-height: 300px;*/
/*       overflow-y: scroll;*/
/*     }*/
/*     .user-details::-webkit-scrollbar{*/
/*       width: 5px;*/
/*     }*/
/*     }*/
/*     @media(max-width: 459px){*/
/*     .container .content .category{*/
/*       flex-direction: column;*/
/*     }*/
/*   } */
.formDataArea label{
    --bs-body-font-size: 20px;
}

html{
   font-size: 14px;
}