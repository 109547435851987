.card {
    width: 400px;
    border: none
}

.btr {
    border-top-right-radius: 5px !important
}

.btl {
    border-top-left-radius: 5px !important
}

.btn-dark {
    color: #fff;
    background-color: #b1b1b2;
    border-color: #a3a4a8
}

.btn-dark:hover {
    color: #fff;
    background-color: #a2a5a8;
    border-color: #b4b4b4
}

.nav-pills {
    display: table !important;
    width: 100%
}

.nav-pills .nav-link {
    border-radius: 0px;
    border-bottom: 1px solid
}

